<template>
    <div class="container" style="padding-top: 60px">
        <div style="margin-bottom: 40px;height: 40px;line-height: 40px;font-size: 32px">网络画板管理平台</div>
        <div class="card" @click="onToUserManager">用户管理</div>
        <div class="card" @click="onToResourceManager">资源管理</div>
        <div class="card" @click="onToOpenSalesManagement">销售管理</div>
        <br/>
        <div class="hr"></div>
        <div class="card" @click="onToMessageManager">消息管理</div>
        <div class="card" @click="onToOpenManager">开放平台管理</div>
        <div class="card" @click="onToOpenV3Manager">开放平台V3</div>
    </div>
</template>

<style scoped>
    .card:hover{
        height: 20xp;
    }
    .container{
        width: 1200px;
        margin: 0 auto;
    }
    .card{
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
        background: #438FFD;
        border-radius: 4px;
        margin: 0 20px;
        display: inline-block;
        color: #fff;
        cursor: pointer;
        font-size: 24px;
        margin-top: 20px;
    }
    .card:hover{
        box-shadow: 0 0 5px 2px #ccc;
    }
    .card_none{
        width: 200px;
        height: 150px;
        line-height: 200px;
        margin: 0 20px;
        display: inline-block;
    }
</style>

<script>
    export default {
        name: "Main",
        data() {
            return {}
        },
        mounted(){
          if(!localStorage.getItem('userInfo')){
              this.$router.push('/login')
          }
        },
        methods: {
            onToUserManager(){
                window.location.href = '/um/'
            },
            onToResourceManager(){
                window.location.href = '/rm/'
            },
            onToOpenManager(){
                window.location.href = '/om/'
            },
            onToMessageManager(){
                window.location.href = '/mm/'
            },
            onToOpenV3Manager(){
                window.location.href = '/omv3/'
            },
            onToOpenSalesManagement(){
                window.location.href = '/sm/'
            }
        }
    }
</script>
