<template>
    <div style="background-image: linear-gradient(180deg, #438FFD 0%, #9ABAE7 57%, #FAFAFA 100%);width: 100%;height: 100%;overflow: hidden">
        <div style="width:600px;margin: 120px auto 0">
            <div style="font-size: 32px;margin-bottom:10px;color: #fff">网络画板管理平台</div>
            <div style="background: rgba(24,24,24,0.20);border-radius: 4px;width: 400px;height: 400px;margin: 0 auto;padding: 60px 0;box-sizing: border-box;">
                <el-input v-model="user" type="text" placeholder="请输入账户名" style="width: 300px;height: 40px;margin: 20px 0"/>
                <el-input v-model="pwd" type="password" placeholder="请输入密码" style="width: 300px;height: 40px;margin: 20px 0"/>
                <el-button type="primary" round style="width: 200px;height: 40px;border-radius: 20px;margin: 20px 0" @click="onLogin">登录</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'login',
        data(){
            return {
                user:'',
                pwd:''
            }
        },
        methods:{
            onLogin(){
                axios.post('/resourceBackAdmin/users/login',{
                    user:this.user,
                    pwd:this.pwd
                }).then((res)=>{
                    localStorage.setItem('userInfo',JSON.stringify(res.data))
                    this.$router.push('/main')
                }).catch(err=>{
                    this.$message.error(err.response.data)
                })
            }
        }

    }
</script>

<style scoped>

</style>
